import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/nonun/...Active Code/new-gatsby-site/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Prologue`}</h2>
    <p>{`Hello dear reader! As you can probably observe, the look and feel of this site have changed a lot. Now, it also has search functionality built into it. Try it by scrolling till the end of the navigation bar. If you want something similar built for you, contact me!`}</p>
    <h2>{`Reading Review`}</h2>
    <p>{`This week I finished Book: Think Like a Monk and Book: How to Develop Self Confidence and Improve Public speaking. But... I am not going to lie I didn't enjoy reading Book: Think Like a Monk. Some parts its great but some parts are just really not in my opinion. Would I recommend reading Book: Think Like a Monk? Yes, but with a word of caution that some parts in it are not entirely scientific and should be taken with a grain of salt. Book: How to Develop Self Confidence and Improve Public speaking was a better than average read. It is definitely worth reading but it is not really an interest catcher. I didn't finish Book: The Obstacle Is the Way because I got too hung up on some good old Sherlock Homes.`}</p>
    <h2>{`Weekly Favourites`}</h2>
    <p><strong parentName="p">{`Experience`}</strong>{` - Hey this is a new type! The experience which I talk about here is the experience of taking 🌲Evergreen notes. It is simply amazing. When you read through the books material and get the gist of it to take detailed and useful notes, you get a feeling of real learning and a sense of accomplishment. I had this experience while taking 🌲Evergreen and smart notes on the Book: How to take smart notes. Before I knew it, I had written several evergreen notes each of which had some sort of an idea behind it.`}</p>
    <p><strong parentName="p">{`Coffee`}</strong>{` - It is no secret that I love coffee. Previously, my horizons were limited to instant coffee but some time ago, I finally made the switch to ground coffee and I am telling you it was worth it! I have been buying all my ground coffee from `}<a parentName="p" {...{
        "href": "https://bluetokaicoffee.com/"
      }}>{`Blue Tokai Coffee Roasters`}</a>{` and my favourite so far has been the `}<a parentName="p" {...{
        "href": "https://bluetokaicoffee.com/collections/coffee/products/kalledeverapura-pulp-sun-dried?variant=1309351560"
      }}>{`Kalledeverapura Pulp Sun Dried`}</a>{`. I know the name is a mouthful but it is a great coffee and at a great price!`}</p>
    <h2>{`Quote of the week`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "796px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b310aad64d04a9d5a0e9ad3633df2afc/d48f1/Quote-7.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABeUlEQVQoz4WS+0/aUBTH/ev3m1myX01cXOKikmXuEeZ8xLgNA8MxiDEwsCVES8sjtDzs6/bezy6VTWA4T+9pTr+n/eR7eu+aUoqHWK7Vkrakp2uxvza9JUISTQQqhHAcE9/JtJ6mDED4CqE18UfXmhIz2HTNmUqBvdsBey8+8HW3RG6vzMeNE45e5Th4ecY3rZ1uFzjPVPikn09fFzjZytP40br3K9W/QN8NqRz9onJcp5nvUDqokn9X4Xz/J1/eFCllq1weXlP+XOcie0Xx/RW31e49UK0CeiHNC5v25QDze5uJEz39K3kYdX4bUmCn1WPz2Q7b62/JPM9Sy5tpM5HJXwdKSaTOtJ5d89AFYOwnuK077EYfr+UTuIL/xfyYK0eO4gijaeB6LnbH5sa6wXEcTNPEMAwsy6JWq6V1EAQrnS0em0TQdWyctkWv46TZ7zp47oDRaIzv+wyHQzzP0+8mTwOnzUTEiChEaLdSfyTiUB8J+ejIj8Vvhyz8olbXpQwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Quote",
            "title": "Quote",
            "src": "/static/b310aad64d04a9d5a0e9ad3633df2afc/d48f1/Quote-7.png",
            "srcSet": ["/static/b310aad64d04a9d5a0e9ad3633df2afc/772e8/Quote-7.png 200w", "/static/b310aad64d04a9d5a0e9ad3633df2afc/e17e5/Quote-7.png 400w", "/static/b310aad64d04a9d5a0e9ad3633df2afc/d48f1/Quote-7.png 796w"],
            "sizes": "(max-width: 796px) 100vw, 796px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Tweet of the week`}</h2>
    <blockquote className="twitter-tweet" data-dnt="true">
  <p lang="en" dir="ltr">
    Every little effort counts in the long run.
    <a href="https://t.co/0Mo82EpWMH">pic.twitter.com/0Mo82EpWMH</a>
  </p>
  &mdash; Tech Burrito (@TechAmazing)
  <a href="https://twitter.com/TechAmazing/status/1327837011395350528?ref_src=twsrc%5Etfw">
    November 15, 2020
  </a>
    </blockquote>
    <h2>{`Books that I will be finishing this week`}</h2>
    <p>{`None. I will be focusing on taking notes on the books that I have read and publishing them. This week I guarantee the 🌲Evergreen notes on Book: How to take smart notes and perhaps even the notes on Book: The Four Hour Work Week. The perfectionist inside me, first wants to make a nicer evergreen notes view on the website but if I can't achieve that this week, I will be posting the notes in a rudimentary article format.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      